#what-i-do{
    background-color: var(--primary-color);
    background-image: url('./assets/I_Tell_Stories.png');
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
    overflow: hidden;
    padding: 100px 20px 100px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#what-i-do_content{
    color: white;
}
#what-i-do_content h3, #what-i-do_content h4{
    margin: 0;
}

#what-i-do_content h3{
    font-size: 35px;
}
#what-i-do_content h4{
    font-size: 20px;
}

#what-i-do .pointer{
    bottom: initial !important;
    position: relative !important;
}

@media (min-width: 1024px){
    #what-i-do{
        padding: 100px;
    }
    #what-i-do_content h3,#what-i-do_content h4,#what-i-do_content p{
        font-weight: 400;
    }
    #what-i-do_content h3{
        font-size: 100px;
    }
    #what-i-do_content h4{
        font-size: 40px;
    }
    #what-i-do_content p{
        margin-top: 100px;
        font-size: 25px;
    }
}

@media (min-width: 1400px){
    #what-i-do{
        padding: 100px;
    }
    #what-i-do_content h3,#what-i-do_content h4,#what-i-do_content p{
        font-weight: 400;
    }
    #what-i-do_content h3{
        font-size: 150px;
    }
    #what-i-do_content h4{
        font-size: 70px;
    }
    #what-i-do_content p{
        margin-top: 100px;
        font-size: 30px;
    }
}