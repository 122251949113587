
    #experience_story{
        background: #1B2735;
        overflow: hidden;
    }
    #story_content{
        position: relative;
        width: 100%;
        min-height: 100vh;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    #top_back_btn{
        cursor: pointer;
    }
    #story_content #top{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: start;
        gap: 0;
        color: white;
    }
    #top h1, #top h2, #top h3{
        margin: 0;
    }

    #top_back{
        margin-bottom: 50px;
    }

    #top_company{
        font-size: 20px;
    }
    #top_role{
        font-size: 30px;
    }
    #bottom_about{
        color: white;
        font-size: 18px;
    }

    @media screen and (min-width: 1024px) {
        #story_content{
            padding: 100px;
            gap: 50px;
        }
        #top_company{
            font-size: 40px;
        }
        #top_role{
            font-size: 100px;
        }
        #bottom_about{
            color: white;
            font-size: 18px;
        }
        #top_location{
            font-size: 25px;
            font-weight: bold;
        }
        #top_duration{
            font-size: 20px;
            font-weight: bold;
        }

        #bottom_about{
            font-size: 18px;
            font-weight: 450;
        }
    }