@import url('./../index.css');

#IAM_Bold{
    background-color: var(--primary-color);
    background-image: url('./assets/Bold.png');
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}
#IAM_Bold_overlay{
    width: 100%;
    height: 100vh;
    background-color: var(--overlay);
    box-sizing: border-box;
    padding: 50px;
}

#IAM_Bold_text{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:40px;
    font-weight: bolder;
    color: white;
    padding-bottom: 60px;
}
#IAM_Bold_text span{
    display: flex;
    flex-direction: column;
    gap: 0px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

#IAM_Bold_text span div:nth-child(2){
    font-size: 100px;
}

#subtext{
    width: 100%;
    /* position: absolute;
    top: 210vh; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    color: white;
}

#subtext .subtext--text{
    font-size: 25px;
    font-weight: bolder;
    cursor: pointer;
}

@media (min-width: 768px){
    #subtext{
        flex-direction: row;
        justify-content: space-between;
    }
    #IAM_Bold_text{
        font-size: 70px;
    }
    #IAM_Bold_text span div:nth-child(2){
        font-size: 200px;
    }
    #subtext .subtext--text{
        font-size: 40px;
    }
}

@media (min-width: 1024px){
    #IAM_Bold_overlay{
        /* padding-top: 110px;
        padding: 100px;
        padding-top: 120px; */
    }
    #IAM_Bold_text{
        font-size: 70px;
    }
    #IAM_Bold_text span{
        /* flex-direction: row; */
        gap: 0;
    }
    #IAM_Bold_text span div:nth-child(2){
        font-size: inherit;
        font-size: 240px;
    }
    #subtext{
        flex-direction: row;
        justify-content: space-between;
    }
    #subtext .subtext--text{
        font-size: 50px;
    }
}

@media (min-width: 1400px){
    #IAM_Bold_overlay{
        padding-top: 110px;
        padding: 100px;
        padding-top: 120px;
    }
    #IAM_Bold_text span{
        flex-direction: row;
        gap: 30px;
    }
    #IAM_Bold_text{
        font-size: 240px;
    }
    #IAM_Bold_text span{
        flex-direction: row;
        gap: 70px;
    }

    #IAM_Bold_text span div:nth-child(2):hover{
        scale: 1.5;
        padding-left: 120px;
    }
}