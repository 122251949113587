#contact{
    min-height: 100vh;
    padding: 10px;
    background-color: rgb(223, 160, 44);
    color: #573F1A;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
}
#youtube-link span, #instagram-link span{
    font-size: 25px;
    font-weight: bold;
}
#instagram-link{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    justify-content: center;
}

#contact_top{
    display: flex;
    flex-direction: column;
}
#top_left{
    width: 100%;
}
#top_left img{
    max-width: 100%;
}

#top_right{
    width: 100%;
}
#top_right h4{
    font-size: 25px;
}
#contact_details{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contact_group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    width: 100%;
}
.contact_group label{
    font-weight: 500;
}
.contact_group span{
    font-weight: bold;
}

#contact_form_container{
    width: 100%;
    height: fit-content;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 20px;
}
#contact_form_tabs--buttons{
    display: block;
    white-space: nowrap;
    overflow: auto;
    padding-bottom: 10px;
    cursor: pointer;
}
#contact_form_tabs--buttons span{
    margin-right: 20px;
    width: 35%;
}

#contact_form_input-fields{
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    gap:5px;
}
#contact_form_input-fields input,#contact_form_input-fields textarea{
    padding: 2px 10px 2px 10px;
    box-sizing: border-box;
    border-radius: 12px;
    height: 40px;
    border: 1px solid black;
}
#contact_form_input-fields .form-input{
    display: flex;
    flex-direction: column;
    width: 100%;
}
#contact_form_input-fields .form-input>label{
    font-size: 15px;
}

#contact_form_input-fields .form-input>textarea{
    height: 200px;
    min-height: 150px;
    resize: vertical;
}

.form_submit,.form_reset{
    padding: 5px;
    font-size: 20px;
    font-weight: bold;
    background-color: black;
    color: white;
    border: 2px solid rgba(0, 0, 0, 0) !important;
    transition: color 0.5s;
    cursor: pointer;
}
.form_submit{
    background-color: #FFB84D;
    color: black;
}

.form_submit:hover{
    border-color: black !important;
}
.form_reset:hover{
    border-color: #FFB84D !important;
    color: #FFB84D;
}

/* Socials */
#contact_form-socials{
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
#contact_form-socials a>img{
    width: 25px;
}

@media (min-width:768px) {
    #contact{
        padding: 30px;
    }
    #contact_top{
        flex-direction: row;
        gap: 20px;
    }
    #top_right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
    }
    #top_left, #top_right{
        width: 50%;
    }
    #top_right h4{
        font-size: 40px;
        margin: 0;
    }

    #contact_form_input-fields input,#contact_form_input-fields textarea{
        font-size: 18px;
    }
    #contact_form_container{
        padding: 20px;
    }
    .contact_group label{
        font-weight: 500;
        font-size: 18px;
    }
    .contact_group span{
        font-weight: bold;
        font-size: 20px;
    }

    #contact_form_tabs--buttons{
        padding: 0;
        display: flex;
    }
    #contact_form_tabs--buttons span{
        width: 35.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
    #contact_form_input-fields{
        display: grid;
        grid-template-areas: 'firstName lastName'
        'email email'
        'msg msg'
        'submit reset';
    }
    #fname-group{
        grid-area: firstName;
    }
    #lname-group{
        grid-area: lastName;
    }
    #email-group{
        grid-area: email;
    }
    #message-group{
        grid-area: msg;
    }
    .form_submit{
        grid-area: submit;
    }
    .form_reset{
        grid-area: reset;
    }
    #youtube-link span, #instagram-link span{
        font-size: 40px;
        font-weight: bold;
    }

    /* Hover/Click Effect on Tabs */
    #contact_form_tabs--highlight{
        width: 100%;
    }
    #contact_form_tabs--highlight>div{
        width: 33.333%;
        height: 2px;
        background-color: black;
    }
    #contact_form-socials a>img{
        width: 30px;
    }
}

@media (min-width:1024px) {
    #contact{
        padding: 50px;
    }
    #top_right h4{
        font-size: 50px;
    }
    .contact_group label{
        font-size: 20px;
    }
    .contact_group span{
        font-size: 25px;
    }

    #contact_form_input-fields{
        display: grid;
        grid-template-areas: 'firstName firstName lastName lastName msg msg msg msg msg msg msg msg msg'
        'email email email email msg msg msg msg msg msg msg msg msg'
        'submit reset space space msg msg msg msg msg msg msg msg msg';
        /* Rows | Columns */
        grid-gap: 20px 30px;
    }

    #youtube-link span, #instagram-link span{
        font-size: 50px;
        font-weight: bold;
    }

    #contact_form-socials a>img{
        width: 40px;
    }
    #contact_form_container{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .contact_group label{
        font-weight: 500;
        font-size: px;
    }
    .contact_group span{
        font-weight: bold;
        font-size: 20px;
    }
    #contact_form_tabs--buttons span{
        font-size: 30px;
    }
}

@media (min-width:1400px) {
    #contact{
        padding: 50px 100px 50px 100px;
        gap: 50px;
    }
    #top_left{
        max-width: 50%;
        width: fit-content;
    }
    #top_left img{
        max-width: none;
        width: 648px;
        height: 422px;
    }
    #top_right h4{
        font-size: 100px;
    }
    .contact_group label{
        font-size: 30px;
    }
    .contact_group span{
        font-size: 35px;
    }
    #contact_form_input-fields input,#contact_form_input-fields textarea{
        font-size: 20px;
        height: 50px;
    }
    #contact_form_input-fields textarea{
        height: 250px !important;
    }
    #contact_form_tabs--buttons span{
        font-size: 30px;
        padding: 10px;
    }
}