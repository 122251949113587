#recommendations{
    padding: 20px;
    color: white;
    min-height: 100vh;
    box-sizing: border-box;
    background: transparent;
    overflow: hidden;
}

#recommendations_pages{
    width: 100%;
    height: 20%;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:30px
    
}
#recommendations_body{
    width: 100%;
    height: 100%;
    display: flex;
}
#recommendations_body--content{
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    cursor: grab;
}
#recommendations_body--content:active{
    cursor: grabbing;
}

#recommendations_body--content::-webkit-scrollbar {
    display: none;  /* WebKit */
}

@media (min-width:1024px) {
    #recommendations{
        padding: 50px;
    }
    #recommendations_pages{
        font-size: 40px;
    }
    #recommendations #story_content{
        padding: 30px;
    }
}