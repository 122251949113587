.shop-item{
    width: 100%;
    max-width: 500px;
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 20px;
    box-sizing: border-box;
    cursor:help;
}

.shop-item.orange{
    background-color: rgb(223, 160, 44);
}
.shop-item.red{
    background-color: rgb(255 36 36);
}
.shop-item.bold{
    font-weight: bold;
    font-style: normal;
}
.shop-item.epic{
    font-weight: bold;
    font-style: italic;
}

.shop-item_top{
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    align-items: start;
    margin-bottom: 20px;
}
.shop-item--heading{
    font-size: 30px;
}
.shop-item--sub-heading{
    text-transform: uppercase;
    font-size: 15px;
}

.shop-item_bottom{
    display: grid;
    grid-template-areas:
      "delivery delivery delivery view"
      "outcome outcome outcome view";
    justify-content: space-between;
    align-items: center;
}
.shop-item--delivery{
    grid-area: delivery;
}
.shop-item--outcome{
    grid-area: outcome
}
.shop-item--view{
    grid-area: view;
}
.shop-item--view img{
    width: 25px;
}

@media (min-width: 1400px) {
    .shop-item{
        max-width: 500px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .shop-item--heading{
        font-size: 50px;
    }
    .shop-item--sub-heading{
        font-size: 20px;
    }
    .shop-item_bottom{
        font-size: 25px;
    }
    .shop-item--view img{
        width: 50px;
    }
}