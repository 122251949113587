@import url('./../index.css');

#worth-it{
    background-color: var(--primary-color);
    background-image: url('./assets/WorthIt.png');
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow: hidden;
    padding: 100px 10px 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#worth-it_top{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-between;
}
#worth-it_right{
    display: none;

}
#worth-it_left{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}
#worth-it_left--container{
    width: 90%;
    height: fit-content;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.742);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

#worth-it_left--container div:nth-child(1),#worth-it_right--container span:nth-child(1){
    font-size: 18px;
    font-weight: 500;
    color: white;
}
#worth-it_left--container div:nth-child(2),
#worth-it_right--container span:nth-child(2){
    font-size: 55px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
}

#recommend-services_anchor{
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
}
#recommend-services_anchor span:nth-child(1){
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: white;
}
#recommend-services_anchor .pointer{
    bottom: initial !important;
    position: relative !important;
}
@media (max-width: 320px) {
    #worth-it_left--container div:nth-child(2){
        font-size: 40px;
    }
}
@media (min-width: 1024px) {
    #worth-it{
        background-position:initial;
    }
    #worth-it_left{
        width: fit-content;
        display: flex;
        justify-content: left;
        align-items: center;
        height: 100%;
    }
    #worth-it_left--container{
        /* max-width: 80%; */
        width: 600px;
        height: 265px;
        padding: 20px;
    }

    #worth-it_left--container div:nth-child(1),#worth-it_right--container span:nth-child(1){
        font-size: 40px;
        font-weight: 500;
        color: white;
    }
    #worth-it_left--container div:nth-child(2),#worth-it_right--container span:nth-child(2){
        font-size: 90px;
        font-weight: bold;
        color: white;
    }
    #worth-it_right{
        display: flex;
        justify-content: right;
        width:fit-content;
    }
    #worth-it_right--container{
        width: fit-content;
        display: flex;
        flex-direction: column;
        writing-mode: vertical-rl;
        transform: rotate(180deg) !important;
    }
    #worth-it_right--container span:nth-child(2){
        font-size: 75px;
    }
    #recommend-services_anchor span:nth-child(1){
        font-size: 30px;
    }
}

@media (min-width: 1400px) {
    #worth-it_left--container{
        max-width: none;
        width: 858px;
        height: 265px;
    }
    #worth-it_left--container div:nth-child(2){
        font-size: 150px;
    }
    #worth-it_right--container span:nth-child(2){
        font-size: 120px;
    }
    #recommend-services_anchor span:nth-child(1){
        font-size: 40px;
    }
}