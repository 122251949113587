#things-i-do{
    background-color: var(--primary-color);
    background-image: url('./assets/things_i_do.png');
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
}

#tid_overlay{
    background-color: #ffffffb5;
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

#tid_overlay--top,#tid_overlay--content,#tid_overlay--bottom{
    display: flex;
    justify-content: left;
    align-items: center;
}
#tid_overlay--top h4{
    margin: 0;
    font-size: 40px;
    text-align: left;
}
#tid_overlay--content{
    text-align: left;
    font-size: 20px;
}
#tid_overlay--bottom{
    box-sizing: border-box;
}
#tid_overlay--bottom a{
    width: 100%;
    background-color: #181616;
    padding: 5px 20px 5px 20px;
    text-align: center;
    text-decoration: none;
    color: white;
    border-radius: 20px;
    font-size: 25px;
    text-transform: uppercase;
}
@media (min-width:768px){
    #things-i-do{
        padding: 120px;
    }
    #tid_overlay{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    #tid_overlay--top h4{
        font-size: 60px;
    }
    /* #tid_overlay--content{
        font-size: 25px;
    } */
    #tid_overlay--bottom a{
        width: 100%;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1024px) {
    #tid_overlay{
        padding: 50px;
        height: 100%;
    }
    #tid_overlay--bottom{
        justify-content: center;
    }
    #tid_overlay--bottom a{
        width: 342px;
        height: 64px;
    }
}

@media (min-width: 1400px) {
    #things-i-do{
        padding: 200px;
    }
    #tid_overlay--top,#tid_overlay--content,#tid_overlay--bottom{
        justify-content: center;
        align-items: center;
    }
    #tid_overlay--top h4{
        font-size: 100px;
    }
    #tid_overlay--content{
        font-size: 30px;
        text-align: center;
    }
    
    #tid_overlay--bottom a{
        width: 342px;
        height: 64px;
        font-size: 30px;

    }
}