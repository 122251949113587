@import url('../index.css');

nav{
    display: flex;
    background-color: var(--primary-color);
    position: sticky;
    top: 0;
    z-index: 9000;
}

nav, #list-container{
    gap: 5px;
    flex-direction: column;
}
.menu{
    padding-left: 20px;
    cursor: pointer;
}
.menu svg{
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#list-container {
    display: flex;
    max-height: 0;
    height: fit-content;
    transition: all 1s;
    padding-left: 30px;
    overflow: hidden;
}
#list-container.show {
    max-height: 600px
}

/* Burger */
.frstbar, .scndbar, .thrdbar {
    transition: all 0.35s linear;
    transform: rotate(0deg);
    transform-origin: 0% 50%;
}
#burgericon.open .frstbar {
    transform: translateY(-12px) translateX(0) rotate(45deg);
}
#burgericon.open .thrdbar {
    transform: translateY(13px) translateX(0) rotate(-45deg);
}
#burgericon.open .scndbar {
    width: 0;
    opacity: 0;
}

/* Desktop */
@media (min-width: 1400px){
    nav{
        width: 80%;
        height: 80px;
        border-bottom-right-radius: 50px;
    }
    nav, #list-container{
        display: flex;
        flex-direction: row;
    }
    .menu{
        display: none;
    }
    #list-container{
        width: 100%;
        max-height: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-left: 0;
    }
}

@keyframes expand {
    0% {
        max-height: 0;
    }

    100% {
        max-height: var(--max-content-height);
    }
}

/* #list-container {
    max-height: 0;
    overflow: hidden;
}

#list-container.show {
    max-height: 500px; 
} */

