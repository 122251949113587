@import url('./../index.css');

#experience_overview{
    background-color: var(--primary-color);
    background-image: url('./assets/experience_1.png');
    background-attachment: fixed;
    background-size: cover;
    min-height: 100vh;
    overflow: hidden;
}
#experience_overlay{
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.631);
    padding: 10px;
    box-sizing: border-box;
}

#IAM_Experienced{
    height: 250px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
#IAM_Experienced div div:nth-child(1){
    font-weight: 500;
    font-size: 30px;
}
#IAM_Experienced div div:nth-child(3){
    font-weight: bold;
    font-size: 40px;
}

#IAM_Experienced_Overview{
    color: white;
    display: flex;
    flex-direction: column;
}

#IAM_Experienced_Overview--bullets{
    display: flex;
    flex-direction: column;
    font-size: 50px;
    font-weight: bold;
}

#experience_cards{
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;
}

@keyframes rotate-x {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(360deg);
    }
}

@media screen and (min-width:768px) {
    #IAM_Experienced div div:nth-child(3){
        font-size: 80px;
    }
}

@media screen and (min-width: 1024px) {
    #experience_overlay{
        padding: 80px 50px 50px 50px;
    }

    #IAM_Experienced{
        margin-bottom: 150px;
    }
    #IAM_Experienced div div:nth-child(1){
        font-size: 50px;
    }
    #IAM_Experienced div div:nth-child(3){
        font-size: 120px;
    }

    #IAM_Experienced_Overview{
        flex-direction: row;
        margin-bottom: 150px;
    }
    #IAM_Experienced_Overview div{
        width: 50%;
    }
    #IAM_Experienced_Overview--description{
        font-size: 30px;
        font-weight: 500;
    }
    #IAM_Experienced_Overview--bullets{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 70px;
    }
    #IAM_Experienced_Overview--bullets{
        width: 290px;
    }

    #experience_cards{
        margin-top: 50px;
    }
}

@media (min-width:1400px) {
    #IAM_Experienced div div:nth-child(3){
        font-size: 150px;
    }
    #experience_cards{
        flex-direction: row;
        gap: 0;
    }
}