.nav_button{
    transition: all 1s;
    padding: 10px;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    border-bottom: 1px solid rgba(255, 255, 255, 0.348);
}

.nav_button--hover{
    height: 2px;
    width: 100%;
    max-width: 0;
    background-color: white;
    transition: all 1s;
}
.nav_button:hover .nav_button--hover{
    max-width: 100%;
}

/* Desktop */
@media (min-width: 1024px){
    .nav_button{
        border: none;
        font-size: 25px;
        border-radius: 12px;
    }
}