@import url('./../index.css');

#experience_overview{
    min-height: 100vh;
    overflow: hidden;
}

.project{
    min-height: 100vh;
    width: 100%;
}