.experience_card{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* max-width: 500px; */
    width: 100%;
    text-align: center;
}

.experience_card--line{
    height: 5px;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 15px;
}

.experience_card--time{
    font-size: 20px;
    font-weight: bold;
}

.experience_card--company{
    font-weight: bold;
    font-size: 35px;
}
.experience_card--role{
    margin-bottom: 30px;
    min-height: 100px;
}
.experience_card--view{
    font-weight: 500;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0);
}
.experience_card--view, .experience_card--role{
    font-size: 25px;
}

@media (min-width:768px) {
    .experience_card--role {
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 1024px) {
    .experience_card{
        max-width: 100%;
    }
    .experience_card--time, .experience_card--company, .experience_card--role, .experience_card--view{
        padding: 0 30px;
        font-size: 40px;
    }
    /* .experience_card--time{
        height: 100px;
    } */
    .experience_card--role{
        margin-bottom: 70px;
    }
    .experience_card--view:hover{
        border-color: white;
    }

}