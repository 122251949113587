@import url('./../index.css');

#hero{
    background-color: var(--primary-color);
    background-image: url('./assets/hero.png');
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}

#hero_socials{
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    font-weight: 500px;
    padding-top: 20px;
}

#hero_socials a{
    color: white;
    text-decoration: none;
    text-transform: capitalize;
}

#hero_content{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 100px);
}

#hero_content--lotus{
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
}
#hero_content--lotus img{
    width: 100%;
    filter: brightness(0) invert(1);
}

#hero_content--name{
    font-size: 25px;
    font-weight: lighter;
    text-transform: uppercase;
    color: white;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
#hero_content--occupation{
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

#techstack{
    display: flex;
    padding-bottom: 5%;
    cursor: pointer;
    justify-content: center;
}
#techstack div{
    color: white;
    font-size: 20px;
    font-weight: 500;
}

@media (min-width: 1024px){
    #hero_content{
        padding: 20px 50px 20px 50px;
    }

    #hero_content--lotus{
        width: 15rem;
        max-width: 20%;
        margin: 0;
    }
    #hero_content--name{
        font-size: 45px;
        width: auto;
        margin: 0;
        text-align: left;
    }
    #hero_content--occupation,#techstack div{
        font-size: 30px;
        width: auto;
        margin: 0;
        text-align: left;
    }

    #techstack{
        justify-content: left;
    }
    #techstack div{
        font-size: 30px;
    }

    /* Interaction Animation */
    #hero_content--lotus, #hero_content--name, #hero_content--occupation, #techstack div {
        cursor: pointer;
        transform-origin: center !important;
    }

    #techstack--web div{
        transition: color 1s;
    }
    #techstack--web:hover{
        color: var(--good-luck);
    }
    #techstack--android:hover{
        color: var(--android);
    }
    #techstack--desktop:hover{
        color: var(--desktop);
    }
}

@media (min-width: 1400px){
    
    #hero_socials{
        font-size: 30px;
    }
    
    #hero_content--lotus img{
        width: 100%;
        filter: brightness(0) invert(1);
    }
}