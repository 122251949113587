#skills{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
#skills_intro{
    display: flex;
    flex-direction: column;
}

#skills_into--left img{
    max-width: 100%;
    width: 100%;
}
#skills_into--right h3{
    font-size: 40px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 10px;
}
#skills_into--right p{
    font-weight: 500;
}
#skills_list{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
#skills_list > span{
    font-size: 40px;
    font-weight: bold;
}
.skills_list--list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
#skills_list--list{
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.skills_list--list span{
    font-size: 22px;
    font-weight: normal;
}

#skills_list_languages{
    display: flex;
    padding: 10px;
    user-select: none;
    gap: 50px;
}

.skill-item{
    min-width: 100px;
    width: 100px;
    height: 250px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
}
.skill-item div{
    text-align: center;
}
.skill-item img{
    width: 100%;
    height: 200px;
}
.skill-item--text{
    height: 50px;
}

#skills_list_languages::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
    height: 10px;
  }
  #skills_list_languages::-webkit-scrollbar-track {
    background: black;        /* color of the tracking area */
  }
  #skills_list_languages::-webkit-scrollbar-thumb {
    background-color: grey;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }

  @media screen and (min-width: 1024px) {
    #skills{
        padding: 50px;
        gap: 100px;
    }
    #skills_intro{
        flex-direction: row;
    }
    #skills_into--left{
        width: 30%;
    }
    #skills_into--right{
        width: 70%;
        padding-left: 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
    #skills_into--right h3{
        font-size: 70px;
        margin: 0;
    }
    #skills_into--right p{
        font-size: 30px;
    }

    #skills_into--left img{
        max-width: 100%;
        width: 499px;
        height: 409px;
    }
    #skills_list > span{
        font-size: 70px;
    }
    .skills_list--list span{
        font-size: 30px;
    }
    .skill-item{
        min-width: 200px;
        width: 200px;
        font-size: 25px;
    }
  }