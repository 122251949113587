@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');
@import url('./../index.css');

#IAM_Creative{
    background-color: var(--primary-color);
    background-image: url('./assets/Creative.png');
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}
#IAM_Creative_overlay{
    width: 100%;
    height: 100vh;
    background-color: var(--overlay);
    box-sizing: border-box;
    padding: 50px;
}

#IAM_Creative_text{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-bottom: 60px;
    font-family: "Julius Sans One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
#IAM_Creative_text span{
    display: flex;
    flex-direction: column;
    gap: 0px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
#IAM_Creative_text span div:nth-child(2){
    font-size: 60px;
}
#subtext .subtext--text{
    font-size: 25px;
    font-weight: bolder;
    cursor: pointer;
}

@media (min-width:768px) {
    #IAM_Creative_text{
        font-size: 240px;
    }
    #IAM_Creative_text span div:nth-child(1){
        font-size: 50px;
    }
    #IAM_Creative_text span div:nth-child(2){
        font-size: 100px;
    }
}
@media (min-width: 1024px){
    #IAM_Creative_overlay{
        padding-top: 110px;
        padding: 100px;
        padding-top: 120px;
    }
    #IAM_Creative_text{
        font-size: 240px;
    }
    #IAM_Creative_text span div:nth-child(1){
        font-size: 70px;
    }
    #IAM_Creative_text span div:nth-child(2){
        font-size: 170px;
    }
    #subtext{
        flex-direction: row;
        justify-content: space-between;
    }
    #subtext .subtext--text{
        font-size: 50px;
    }
}
@media (min-width: 1400px){
    #IAM_Creative_overlay{
        padding-top: 110px;
        padding: 100px;
        padding-top: 120px;
    }
    #IAM_Creative_text{
        font-size: 240px;
    }
    #IAM_Creative_text span div:nth-child(1){
        font-size: 100px;
    }
    #IAM_Creative_text span div:nth-child(2){
        font-size: 250px;
    }
    #subtext{
        flex-direction: row;
        justify-content: space-between;
    }
    #subtext .subtext--text{
        font-size: 50px;
    }
}