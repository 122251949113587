
.recommendation{
    width: 100%;
    height: 700px;
    max-height: 700px;
    background-color: #D9D9D9;
    padding: 30px 10px 20px 10px;
    box-sizing: border-box;
    color: black;
    border-radius: 20px;
    user-select: none;
    display: inline-block;
    margin-right: 20px;
}

.recommendation_top{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 30%;
}
.recommendation--icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.recommendation--icon img{
    width: 100px;
}
.recommendation_user{
    display: flex;
    flex-direction: column;
}
.recommendation_user--name{
    font-size: 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
    overflow: hidden;
}
.recommendation_user--relationship{
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
}

.recommendation_content{
    max-height: 100%;
    overflow-y: auto;
    height: 60%;
    white-space: normal;
}
.recommendation_content::-webkit-scrollbar {
    width: 8px;
}

.recommendation_content::-webkit-scrollbar-track {
    background: #f1f1f100;
}

.recommendation_content::-webkit-scrollbar-thumb {
    background-color: #161616;
    border-radius: 4px;
    border-radius: 20px;
}

.recommendation_content::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.recommendation_footer{
    height: 10%;
    display: flex;
    justify-content: left;
    align-items: end;
}
.recommendation_find-on--linkedin{
    width: 25px;
}

@media (min-width:768px) {
    .recommendation{
        padding: 30px;
        max-height: 700px;
        /* width: 500px; */
        width: 100%;
    }
    .recommendation_top{
        flex-direction: row;
    }
    .recommendation_user{
        justify-content: center;
    }
    .recommendation_user--name,.recommendation_user--relationship{
        font-size: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 350px;
    }
    .recommendation--icon img{
        width: 100px;
    }
    .recommendation_content{
        font-size: 20px;
    }
    .recommendation_find-on--linkedin{
        width:30px
    }
}

@media (min-width:1200px) {
    .recommendation{
        width: 50%;
    }
}