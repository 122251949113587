#shop{
    padding: 0;
    min-height: 100vh;
}

#shop_bottom .pointer{
    bottom: initial !important;
    position: relative !important;
}

#shop_top{
    width: 100%;
    height: 20%;
}
#partnered_heading{
    font-size: 40px;
    font-weight: bold;
}
#partnered_text{
    font-size: 20px;
    font-weight: 500;
}

#shop_bottom{
    width: 100%;
}
#contact_cta{
    width: 100%;
    text-align: center;
    font-size: 25px;
    height: 20%;
}

#content_price{
    display: none;
    font-size: 40px;
    font-style: italic;
}
#content_shop-cta{
    width: 100%;
    height: fit-content;
}

#shop_content{
    display: flex;
    flex-direction: column-reverse;
    margin: 20px 0;
    gap: 20px;
    position: relative;
    height: 60%;
    justify-content: start;
}
#content_shop-cta a{
    width: 100%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    border-radius: 20px;
    border: none;
    text-decoration: none;
    color: black;
    background-color: white;
}
#content_shop-cta > a > svg{
    width: 25px;
}

#content_right{
    display: flex;
    flex-direction: column;
    gap: 20px;
}


@media (min-width:1024px) {
    #shop{
        padding: 20px;
    }

    #shop_top{
        margin-bottom: 30px;
    }
    #partnered_heading{
        font-size: 70px;
    }
    #partnered_text{
        font-size: 40px;
    }

    #shop_content{
        flex-direction: row;
    }
    #content_left{
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    #content_right{
        width: 35%;
    }
    #content_price{
        display: inline-block;
        margin-top: 50px;
        font-size: 70px;
    }
    #content_shop-cta{
        display: flex;
        justify-content: center;
    }
    #content_shop-cta > a{
        margin-bottom: 100px;
        max-width: 400px;
        height: 100px;
    }
}

@media (min-width: 1400px) {
    #partnered_text{
        font-size: 50px;
    }
    #content_price{
        font-size: 120px;
    }
    #content_shop-cta > a{
        max-width: 500px;
        height: 150px;
        font-size: 50px;
    }
    #content_shop-cta > a > svg{
        width: 50px;
    }
}