#education{
    padding: 10px;
    background: linear-gradient(to bottom, #E3E4E3 0%, #ECEDEC 6%, #FFFFFF 100%);
}

/* OVERVIEW */
#overview, #bottom{
    display: flex;
    flex-direction: column;
}
#top{
    display: flex;
    flex-direction: column;
}
#top_img img{
    width: 100%;
}

#bottom{
    gap: 10px;
}
.education{
    width: 100%;
    height: 392px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: url('./cursor/eyes.cur'),pointer;
}
.education#vc{
    background-color: var(--varsity);
}
.education#rgit{
    background-color: var(--richfield);
}
.education_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.education_logo{
    height: 168px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.education_logo img{
    width: 100%;
    object-fit: contain;
}
.education_title{
    font-size: 30px;
    font-weight: bold;
    color: white;
    width: 100%;
    text-align: center;
}
.education_tools{
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.education_tools div:nth-child(1){
    display: none;
    gap: 10px;
}
.education_tools--view, .education_tools div a{
    font-size: 25px;
    color: white;
    font-weight: 250;
    text-decoration: underline;
    cursor: pointer;
}
.education_tools div a{
    color: white;
    text-decoration: none;
}
.education_tools--yearCompleted{
    font-size: 25px;
    color: white;
    font-weight: 500;
}
/* END OVERVIEW */

/* STORY */
#story{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
#story_back{
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 30px;
}
#story_back div{
    display: flex;
    align-items: center;
}
#story_top{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.badge{
    width: 100%;
    height: 392px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 10px;
    box-sizing: border-box;
}
.badge img{
    width: 100%;
}

#badge_vc{
    background-color: var(--varsity);
}
#badge_rgit{
    background-color: var(--richfield);
}

#adademicRecord{
    background-color: var(--good-luck);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 404px;
    width: 70%;
    height: 50px;
    /* transform: translate(-10px,130px); */
    font-size: 20px;
    font-weight: 600;
}
#story_top--right{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.educationDetails--top_instiute{
    font-size: 20px;
}
.educationDetails--top_program{
    font-size: 30px;
    font-weight: bold;
}
/* END STORY */


@media screen and (min-width: 1024px) {
    #education{
        padding: 50px 80px 50px 80px;
    }
    #overview{
        gap: 30px;
    }
    /* OVERVIEW */
    #top{
        flex-direction: row;
        gap: 30px;
    }
    #top_img img{
        width: 400px;
    }
    #top_journey{
        font-size: 20px;
    }
    #top_journey p{
        margin-top: 0;
    }
    #bottom{
        flex-direction: column;
        justify-content: left;
        align-items: center;
        gap: 70px;
    }
    .education{
        padding: 30px;
        height: 392px;
    }
    .education_content{
        gap: 20px;
    }
    .education_logo{
        width: 499px;
        height: 168px;
    }
    .education_tools div:nth-child(1){
        display: flex;
    }
    /* END OVERVIEW */

    /* STORY */
    #story_top{
        flex-direction: row;
        gap: 30px;
    }
    #story_top--right{
        justify-content: space-between;
    }
    .educationDetails--bottom{
        font-size: 18px;
    }
    .badge{
        width: 593px;
    }
    #adademicRecord{
        width: 404px;
        height: 60px;
        font-size: 30px;
    }
    .educationDetails--top_instiute{
        font-size: 20px;
    }
    .educationDetails--top_program{
        font-size: 30px;
    }
    #story_info{
        font-size: 20px;
    }
    /* END STORY */
}

@media screen and (min-width: 1400px) {

    /* OVERVIEW */
    #top_img img{
        width: 519px;
        height: 425px;
    }
    #top_journey{
        font-size: 30px;
    }
    #bottom{
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: 70px;
    }
    .education{
        padding: 30px;
        height: 392px;
    }
    .education_content{
        gap: 20px;
    }
    .education_logo{
        width: 499px;
        height: 168px;
    }
    .education_tools div:nth-child(1){
        display: flex;
    }
    /* END OVERVIEW */

    /* STORY */
    #story_top{
        flex-direction: row;
        gap: 30px;
    }
    #story_top--right{
        justify-content: space-between;
    }
    .educationDetails--bottom{
        font-size: 40px;
    }
    .badge{
        width: 593px;
    }
    #adademicRecord{
        width: 404px;
        height: 60px;
        font-size: 30px;
    }
    .educationDetails--top_instiute{
        font-size: 40px;
    }
    .educationDetails--top_program{
        font-size: 50px;
    }
    #story_info{
        font-size: 30px;
    }
    /* END STORY */
}