@import url('./../../index.css');

.personalityTrait{
    font-weight: bold;
    font-size: 20px;
    height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px 5px 10px;
    background-color: var(--good-luck);
    border-radius: 20px;
    cursor: pointer;
    text-transform: capitalize;
}