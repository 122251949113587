@import url('./../index.css');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

#IAM_Educated{
    background-color: var(--primary-color);
    background-image: url('./assets/educated.png');
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}

#IAM_Educated_text{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-bottom: 60px;
    background-color: rgba(0, 0, 0, 0.49);
    flex-direction: column;
    gap: 0px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    cursor: pointer;
}
#IAM_Educated_text div:nth-child(2){
    font-size: 50px;
}

@media (min-width: 1024px){
    #IAM_Educated{
        display: flex;
        justify-content: right;
    }
    #IAM_Educated_text{
        width: fit-content;
        font-size: 120px;
        margin-right: 10%;
        background-color: rgba(0, 0, 0, 0);
        color: black;
    }
    #IAM_Educated_text div:nth-child(1){
        font-size: 50px;
    }
    #IAM_Educated_text div:nth-child(2){
        font-size: 130px;
    }
}

@media (min-width: 1400){
    #IAM_Educated{
        display: flex;
        justify-content: right;
    }
    #IAM_Educated_text{
        width: fit-content;
        font-size: 120px;
        margin-right: 10%;
        background-color: rgba(0, 0, 0, 0);
        color: black;
    }
    #IAM_Educated_text div:nth-child(1){
        font-size: 100px;
    }
    #IAM_Educated_text div:nth-child(2){
        font-size: 180px;
    }
}