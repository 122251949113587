#about{
    padding: 40px 20px 20px 20px;
    display: flex;
    flex-direction: column;
}

#left_profile{
    width: 100%;
}
#left_name{
    font-weight: bold;
    font-size: 30px;
    margin: 30px 0 0 0;
}
#left_occupation{
    font-weight: 400;
    font-size: 25px;
    margin: 0 0 30px 0;
}
#left_descriptors{
    font-weight: bold;
}
#right_personalityTraits{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}


@media (min-width: 1024px){
    #about{
        flex-direction: row;
    }

    #left{
        width: 30%;
        padding-left: 100px;
        box-sizing: border-box;
    }
    #right{
        width: 70%;
        padding-left: 70px;
    }

    #left_profile{
        max-width: 366px;
    }
    #left_name{
        font-size: 30px;
    }
    #left_occupation{
        font-weight: 400;
        font-size: 20px;
    }
    #left_descriptors{
        font-size: 20px;
    }
    #left_description{
        font-size: 20px;
    }
    #right_intro{
        font-size: 50px;
        font-weight: 600;
        margin: 0;
    }
    #right_about{
        font-size: 18px;
    }
}

@media (min-width:1400px) {
    #left_name{
        font-size: 40px;
    }
    #left_occupation{
        font-weight: 400;
        font-size: 25px;
    }
    #left_descriptors{
        font-size: 25px;
    }
    #left_description{
        font-size: 25px;
    }
    #right_about{
        font-size: 25px;
    }
}