@import url('https://fonts.googleapis.com/css2?family=Playwrite+BE+WAL:wght@100..400&display=swap');

footer{
    background-color: #141414;
    padding: 10px;
    font-size: 15px;
    color: white;
    text-align: center;
    height: auto;
}

footer span{
    font-family: "Playwrite BE WAL", cursive;
}

footer a{
    text-decoration: none;
    color: white;
    font-weight: bolder;
}