@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('./style/space.css');
:root {
  --primary-color: #141414;
  --good-luck: #FFB84D;
  --arduino: #316E99;
  --richfield: #124A9A;
  --varsity: #105A7E;
  --overlay: #00000045;
  --overlay-white: #FFFFFFC2;
  --richfield-project: #004CA2;
  --duwtf-project: #1F1F1F;
  --android: #3DDC84;
  --desktop: #0d6efd;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section{
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
}