
#just-tees{
    padding: 10px;
    box-sizing: border-box;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}
#just-tees_content{
    display: flex;
    flex-direction: column;
}
#just-tees_content--left img{
    width: 100%;
}
#just-tees_content--left marquee{
    font-size: 20px;
    font-weight: bold;
}

#just-tees_content--right h3{
    font-size: 40px;
    text-shadow: 0 4px 5px #00000048;
    margin-bottom: 0;
}
#just-tees_content--right p{
    text-shadow: 0 4px 5px #00000048;
}

#just-tees_util--icons{
    display: flex;
    gap: 10px;
}
#just-tees_util--icons a>img{
    width: 40px;
    height: 40px;
}

@media (min-width:1024px) {
    #just-tees{
        padding: 120px 50px 50px 50px;
    }
    #just-tees_content{
        flex-direction: row;
        gap: 30px;
        /* height: 90vh; */
    }
    #just-tees_content--left,#just-tees_content--right{
        width: 50%;
    }
    #just-tees_content--right{
        vertical-align:top;
    }
    #just-tees_content--right h3{
        font-size: 100px;
        margin: 0;
    }
    #just-tees_content--right p{
        font-size: 30px;
        font-weight: 500;
    }
    #just-tees_util{
        height: 50px;
    }
}